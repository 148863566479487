import React from 'react'
import CustomScrollbar from './CustomScrollbar';
import headerImage from '../img/extras/kd.gif';
import podcastbanner from '../img/fg-white.jpg';
// import smokeImage from '../img/extras/blacksmoke.gif';
// import whiteSmoke from '../img/extras/redsmoke-sm.gif';

const ModalResearch = ()=>{
    const title = `Research & Other Projects`
    const paragraph_1 = `In this episode we delve into the nuts and bolts of machine learning artificial intelligence (ML AI). Kris breaks down the mechanics of neural networks as the duo dispell myths of computer intelligence while offering insights into the future applications of today's bleeding edge technologies.`

    const paragraph_2 = `"Every smart person wants to be corrected, not admired..." - "You don't understand anything until you learn it more than one way."   - Marvin Minsky`
    
    const paragraph_3 = `Podcast RSS Feed: krisdriver.com/feed/rssfeed.xml - Use this RSS url (or click on the rss icon at the top left of the page to copy it to your clipboard) to subscribe to Frivolous Gravitas using your favorite podcast player to catch up on past episodes and listen any time you want. No commercials, no sponsors, no ads, just straight talk on demand and on the go. Original Music by Dreamlife.`

    const whatscoming = "Machine learning artificial intelligence and neural networks. I'm not sure if it's all the buzz or all the rage, but in either case they are the subject of my nerd affections for the past several years. I'm experimenting with artificial intelligence and machine learning with the hope of discovering novel applications for them, creative implementations, and/or pragmatic adaptations in the field of data science. My research is currently focused on applying physics based rendering in graphics engines (Blender, in my case) to kernels, encoders, transformers, (etc.) in machine learning algorithms."
    
    const paragraph_4 = `The hypothesis is, as with other areas of physics and nature more broadly, the optimizations learned by evolution of the universe may well be transferrable to other efficient forms of interconnected features and traits. If we can find algorithms which excel by applying natural laws to them in just the right ways, we may thusly find optimizations hardwired into the fabric of space-time itself, leading to new hardware and ultimately more prolific outcomes on massively scaled systems we carefully engineer to exploit said optimzations of nature.`
    
    const paragraph_5 = `The approach to this is so far to gain deeper intuitions on the subject matter itself. This means understand raycasting and mesh models in 3D graphics engines to use as a foundation for embedded neural nets by modelling bounces of light and the paths each ray traces as the dot products and tensor calculus of trained models. Following this: fluid simulations, collisions and deformations would likely be the next step in experimentation. The hope is that by applying these models in an engine like Blender or Unity we might be able to repurpose long-established rendering functions which are already highly optimized to function on modern desktop computers (as opposed to cloud computing or supercomputers) and hopefully also be able to provide the engineer with some visual feedback which our brains are more highly adept at analyzing quickly.`
    
    const paragraph_6 = `Human eyes and brains are fantastic pattern recognizers. Not all patterns in all situations, but I'm referring to the patterns our brains map to faces, sounds, refraction, colors and transparancies. We notice immediately if something doesn't 'look real', and we're tricked into seeing shapes and colors that aren't there (like watching clouds or shadows). If we leverage our inherent natural optimizations to spot faults in our machine learning algorithms, then maybe we'll be able to engineer more dynamic, more coherent, more useful neural networks by consequence.` 
    
    const paragraph_6b = `My research aims to analyze the crossovers between neural networks as matrix math protocols and the physics based rendering of 3D graphics engines in the hopes to not only train more efficiently, but also detect errors and path-trace outcomes more expediently too. This method may also award the added benefit of tracing shapes and meshes out of point clouds, providing us with an environment to fly through to learn for ourselves how a model arrived at any particular conclusion, although this has yet to be tested and is mostly in the realm of wishful thinking at this point.`
    
    const paragraph_7 = `There will surely be more to come here, so check in a few times per year or follow the podcast. I intend to publish my results eventually, but in the meantime the only place you'll hear updates is going to be on the show or on this website so check back for more or follow on github, rss, twitter, facebook, etc (find links in the "Links" section of krisdriver.com). In the end we're all on this sphere together. We should share our knowledge and experience with one another for everyone's greater good. A candle loses nothing by lighting another flame, and love never divides it only ever multiplies. We should aim to spread knowledge without hoarding it. Doing this responsibly also means we need to support creative projects so the creators are not beholden to their funding agents.`

    const paragraph_8 = `... Speaking of funding, your support is welcomed and greatly appreciated! You can show your support of this work for free simply by clicking the "like" button on social media posts and youTube videos. It goes a long way to promote my work by telling search algorithms that my content is interesting to you. Subscribe and share on social media - it's free for you and goes a long way to helping the channel gain visibility.`
    
    const paragraph_9 = `You can also show financial support by purchasing some of my wares. I've got a couple of books, a feature-length hip hop album, some royalty-free commercial graphics packages, and consulting services should you find yourself in the need. The graphics packs are especially helpful to anyone involved in conducting presentations or keynotes, but they're also perfect for small business on limited marketing budgets or graphics designers who sell their images commercially. Check out the eBay listings for those (links in the "Graphics" section of krisdriver.com).`
   
    let bannerstyle = {
        position: 'relative',
        width: '90%',
        top: '15px',
        height: '20vh',
        backgroundImage: `url(${podcastbanner})`,
        backgroundSize: 'cover',
        backgroundPosition: '-50%',
        boxShadow: '3px 3px 4px 5px black'
    }

    let bannertext = {
        text: 'black',
        // fontSize: '0.8rem',
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
        padding: '0.35rem',
        textShadow: '2px 2px 3px black',
        textOutline: '1px yellow',
        backgroundColor: 'rgba(0,0,0,0.5)'
    }

    return( <>
    <CustomScrollbar universal style={{ 
        margin: '15px',
        width: 'calc( 89vw - 15px )'
        }} 
        renderTrackVertical={ ({ style, ...props})=>{
            const trackStyle = { backgroundColor: 'rgba(0,0,0,0.5)', width: '5px', top: '50px', bottom:'2px', right: '3px'}
            return ( <div style={{...style, ...trackStyle }} {...props} />)
        } }
        renderThumbVertical={ ({ style, ...props}) =>{ 
            const thumbStyle = { backgroundColor: 'rgba(255,255,255,0.75)'}
            return(
                <div style={ {...style, ...thumbStyle} } {...props}>
                </div>
            )
        }
    }>
        <center>
            <div style={bannerstyle}></div>
            <br />

            <h2 style={bannertext}>{title}</h2>
            <h4 style={bannertext}>{whatscoming}</h4>
            
            <iframe title="Neural Networks pt1" width="75%" height="200px" src="https://www.youtube.com/embed/rXV2o9bMFTg" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            
            <br />
            
            <iframe title="Neural Networks pt2" width="75%" height="200px" src="https://www.youtube.com/embed/4vJQU7Yej8s" frameBorder="0" allow="accelerometer; autoplay=0; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </center>
         <div className='modal-text-content'>
        <p>{paragraph_1}</p>
        <div className='graphics-modal'>
        </div>
        <p>{paragraph_2}</p>
        <p>{paragraph_3}</p>
        <p>{paragraph_4}</p>
        <p>{paragraph_5}</p>
        <center>
            <img src={headerImage} className='research-header' alt='CG Art'/>
            
        </center>
        <p>{paragraph_6}</p>
        <p>{paragraph_6b}</p>
        <p>{paragraph_7}</p>
        <p>{paragraph_8}</p>
        <p>{paragraph_9}</p>
    </div>
    </CustomScrollbar>
    </>)
}

export default ModalResearch