import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

export default class CustomScrollbar extends React.Component {
    render() {
        return (
            <Scrollbars {...this.props} autoHide autoHideTimeout={1000} style={{width: '99%'}}>
                {this.props.children}
            </Scrollbars>
        )
    }
}