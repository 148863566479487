import React, { useRef } from 'react';
import Thumbview from './Thumbview';
import Footer from './Footer';

const Main = ()=>{
    const mainRef = useRef();

    return (
        <div className="App-header" ref={mainRef} >

            <div className='div-fade-vert'></div>
            <Thumbview />
            <div className='div-fade'></div>
            <Footer />
        </div>
    )
}

export default Main