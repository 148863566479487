import React from 'react';
import CustomScrollbar from './CustomScrollbar';
import headerImage from '../img/extras/header-mp.jpg';
import vid_animation from "../vid/meltingAnim.webm";

const ModalMeltingPot = ()=>{
    const title = `The Melting Pot: A Canadian Guide to Cooking & Nutrition for managing chronic health conditions`
  
    const paragraph_1 = `A few decades of chronic conditions under my belt inspired me to write "The Melting Pot" to help people with disabilities learn to cook nutritious budget meals that align with healthy living targets and goals. For the longest time I believed I couldn't cook because I had difficulties following directions and recipes. Then I learned over the years that all of cooking is nothing more than heating and mixing. Anyone can learn to cook perfectly delightful meals without measuring or lists of steps, ingredients, tools, etc. It can be learned without cards, or vidoes or notes via intuition and taste testing rather than prescribed formulae.` 

    const paragraph_2 = `I want to help the world see there are alternatives to pre-made food for those of us who struggle with day to day maintenance, but also to reinvigorate the spirit of discovery in people who already learned how to cook but in different ways. Cooking isn't an art because it's exclusive to artists, it's an artform because it is fluid and unique to each individual's experience. The spice of life is in exploration and invention of tastes, textures and flavours. It is my hope that with this book my dear readers may benefit by gaining a deeper appreciation for blending flavours and experimenting in new and novel ways.`

    const paragraph_3 = `That is to say, this is a cook book of another variety. The aim of this book is to promote health and wellness by learning to cook from the senses rather than memorizing recipes. Nutritional information and meal ideas contained herein I've tailored to address some specific obstacles for persons with disability and / or chronic illness(es); including mental disorders, inflammatory chronic conditions such as ulcerative colitis / crohn's diesease, and to help those dealing with weight control & diabetes.`

    const paragraph_4 = `I hope to inspire a love of cooking by emphasizing tuning in to the senses with the aim of discovery - excursions into new realms of flavours and excitement; enough to brighten up even the cloudiest of days. After reviewing decades of journals, studies, course lectures and through gruelling personal experience, I needed a place to organize what I'd learned to be sure I was truly putting my best foot forward in managing / promoting my own health and wellbeing. From meal ideas to chemistry of preservatives and dietary fibers, I felt there was a need for a concise but thorough evaluation of nutrition and health but had difficulty finding clear information from any one source by myself. Since my first foray into the literary realms was a heady philosophically inspired novel, I figured that an artsy picturesque cookbook would be a fitting challenge to take on next. "The Melting Pot" is that final production.`

    const paragraph_5 = `This began as a passion project but quickly unravelled into a bit of an exposé. Almost all of my research and meal planning was developed by countless hours over decades of trial and error. I picked and scoured across hundreds of websites and videos for thousands of hours, pulling ideas and the like from other sufferers of chronic illnesses as well. I also made painstaking efforts to author my counsel not as rules of law, but suggestions in approach. The Melting Pot is a compassionately scripted guide, not a rulebook or rigid code. It helps teach cooking by teaching how to learn to cook. It is my greatest hope that someone will save themselves years of stressful aguish or find some relief in reading The Melting Pot for its holistic approach to wellness and treatments where the system commonly lacks adequate support to sufferers of chonic health conditions.`

    const paragraph_6 = `A fun point of note while making this book I wound up snookered dealing with some fundamental design issues which meant that going back to edit meant a massive amount of tedious corrective work rasterizing text onto each full coloured page. Normally I leave a page rather than get stuck on it while writing, saving it for changes when I've finished more of the project and have a better idea of how to finish it when I come back to it for final editing. Non-fiction is often written this way (I think) when the writing process is just not 'flowing' cohesively, or progresses more like playing hopscotch on meth... So, one would normally be able to go back and edit or redo earlier parts lacking in editorial quality. Unfortunately I could not bring myself to restart in light of the desktop publishing errors I'd made early on in the project through lack of foresight, so as I learned to use Scribus it sort of shows the learning process of making a book when read from start to finish. You can basically witness my learning as I guide you through learning to cook in a new, more inventive way. It's ok for things not to turn out perfectly and it's still worth doing - that's the point. The book is itself a reflection of proof that mistakes are part of the process, that's the ultimate point of my approach to teaching cooking. Keeping with the tradition of my books being themselves recursive metaphors, I'm actually pretty happy the way it turned out with all its faults and failures. "Wabi-Sabi" as the Japanese saying goes, is an aesthetic school of thought that emphasizes the beauty in imperfection.`
    const video = ()=>{
    return (
        <center>
        <div style={{ padding: '5px', backgroundImage: 'linear-gradient( to bottom right, black, white, white, white, black )', borderRadius: '5%', boxShadow: '3px 3px 5px black' }}>
        <video className='vid-style' controls>
            <source src={vid_animation} type="video/webm" autoPlay loop />
        </video>
        </div>
        </center>
    )
    }
    return( <>
    <CustomScrollbar universal style={{ 
        margin: '15px',
        width: 'calc( 89vw - 15px )'
        }} 
        renderTrackVertical={ ({ style, ...props})=>{
            const trackStyle = { backgroundColor: 'rgba(0,0,0,0.5)', width: '5px', top: '50px', bottom:'2px', right: '3px'}
            return ( <div style={{...style, ...trackStyle }} {...props} />)
        } }
        renderThumbVertical={ ({ style, ...props}) =>{ 
            const thumbStyle = { backgroundColor: 'rgba(255,255,255,0.75)'}
            return(
                <div style={ {...style, ...thumbStyle} } {...props}>
                </div>
            )
        }
    }>
    <div className='modal-text-content'>
        <center><a href='https://www.blurb.com/b/9181642-the-melting-pot' target='_blank' rel="noopener noreferrer"><img src={headerImage} className='mp-header' alt='The Melting Pot' /></a></center>
        <br />
        <h2 className='mp-head-text' >{title}</h2>
        <p>{paragraph_1}</p>
        <p>{paragraph_2}</p>
        <p>{paragraph_3}</p>
        <p>{paragraph_4}</p>
        <p>{paragraph_5}</p>
        <p>{paragraph_6}</p>
        <br /><br />
        <center>
            <code style={{fontSize: '50%'}}>(gaudy-renders-seh-wah)</code>
            {video()}
        </center>
    </div>
    </CustomScrollbar>
    </>)
}

export default ModalMeltingPot