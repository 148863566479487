import React from "react";

const Footer = ()=>{
    return(
        <div className='footer-div'>
            <p>This is the footer details... all rights reserved and shit like that</p>
        </div>
    )
}

export default Footer;