import React from 'react'
import CustomScrollbar from './CustomScrollbar';
import coverImage from '../img/extras/album.jpg';

const ModalMusic = ()=>{
    const title = `Emergence: Luctor et Emergo [SOUNDTRACK]`;

const tracklist = ()=>{
return ( <div><ul className='tracks'>
<li>
    00 (Bonus) - Nonsense - Produced by Corbett
</li>
<li>
    00 (Bonus) - Bar Stars - Produced by Blanq Beatz
</li>
<li>
    01 - Moving on - Produced by Don P
</li>
<li>
    02 - Blood Running (Tribute 2) - Produced by Epistra
</li>
<li>
    03 - Believe in Me - Produced by Epistra
</li>
<li>
    04 - Consumer Divorce - Produced by Epistra
</li>
<li>
    05 - Waiting for a Song - Produced by Dov Rohan
</li>
<li>
    06 - Say Goodbye - Produced by Epistra
</li>
<li>
    07 - War of Hate - Produced by Epistra
</li>
<li>
    08 - Real Rap - Produced by Epistra
</li>
<li>
    09 - Gotta Get Away - Produced by Epistra
</li>
<li>
    10 - Calm and Collected - Produced by Kris Driver
</li>
<li>
    11 - Never Come Down - Produced by Corbett
</li>
<li>
    12 - Stacked Against Me - Produced by Epistra
</li>
<li>
    13 - All it Takes pt.1 - Produced by Mike Lightner
</li>
<li>
    14 - All it Takes pt.2 - Produced by Mike Lightner
</li>
<li>
    15 - Good Life - Produced by Elliot Waite
</li>
<li>
    16 - New Life - Produced by Elliot Waite
</li>
<li>
    17 - Sarcasm - Produced by Corbett
</li>
<li>
    18 - Why Am I Alive - Produced by Kris Driver
</li>
<li>
    19 - Take Me - Produced by Mike & Dov
</li>
<li>
    20 - Take Away (Tribute 1) - Produced by Lytos
</li></ul></div>)}

const paragraph_1 = `Promo CD for the book "Emergence: Luctor et Emergo", written/recorded by Kristopher Driver`

const paragraph_2 = `Interesting note for reference, these songs were written and recorded roughly in reverse chronological order, the oldest being near the end and the more recent at the beginning (meaning higher quality rhyming patters / schemes and recording quality lol). It's a span of roughly 15 years on one single album, so you can really get a sense of the progression, mentality / perspective changes, and consistencies that have lasted time.`

const paragraph_3 = `The bonus tracks are essentially the singles, written and recorded after the book was finished and published. The others were written during the book's creation and the earliest songs from long before the concept was even conceived.`

const paragraph_4 = `Tribute tracks are dedicated to my grandmother and mother, whose Jamaican roots and love of music was never worn far from the sleeve.`

    return( <>
    {/* ENTIRE CONTENTS */}
    <CustomScrollbar universal style={{ 
        margin: '15px',
        }} 
        renderTrackVertical={ ({ style, ...props})=>{
            const trackStyle = { backgroundColor: 'rgba(0,0,0,0.5)', width: '5px', top: '50px', bottom:'2px', right: '3px'}
            return ( <div style={{...style, ...trackStyle }} {...props} />)
        } }
        renderThumbVertical={ ({ style, ...props}) =>{ 
            const thumbStyle = { backgroundColor: 'rgba(255,255,255,0.75)'}
            return(
                <div style={ {...style, ...thumbStyle} } {...props}>
                </div>
            )
        }
    }>
    <div className='modal-text-content'>
        <center><a href='https://music.apple.com/us/album/emergence-luctor-et-emergo-official-soundtrack/1296965258' target='_blank' rel="noopener noreferrer"><img src={coverImage} className='album-header' alt='Album Cover - Emergence Soundtrack'/></a></center>
        <h2 className='album-head-text' >{title}</h2>
        <p>{paragraph_1}</p>
        <p>{paragraph_2}</p>
        <p>{paragraph_3}</p>
        <p>{paragraph_4}</p>
        <span className='tracks'>{tracklist()}</span>
    </div>
    </CustomScrollbar>
    </>)
}

export default ModalMusic