import React from 'react'
import CustomScrollbar from './CustomScrollbar';
import headerImage from '../img/extras/header-em.jpg';
import reviewImage from '../img/extras/bookviral.jpg';
 
const ModalEmergence = ()=>{
    const title = `Emergence: Luctor et Emergo`
 
    const paragraph_1 = `Emergence is a coming of age story reminiscent of classic literature in its peculiarirties and lingual artisanship. The work is strongly introspective and deeply metaphorical at all times, aimed at piquing the astute readers' eyes and ears. For those who are looking for a meaty read repleat with verbally playful syllepses yet also degenerating into humor bordering downright morbid in lockstep. It grapples with social and personal psychologies, conflicting virtues and core values, and it aims to articulate indirectly the intrinsic ambiguity of morality we so often casually ignore in our day to day lives despite our acknowledgement of its ruminations' meanings in and to all of our lives. We seek to probe at the most disregarded metaphysical connotations while wrapped in awkward threads of self-reflection to emphasize an art of thinking deeply in artistically alien terms.`

    const paragraph_2 = `Justin is a teen in his senior year. He is desperately seeking an escape from the inner city's demons while also chasing evasive relief from the anxiety which plagues him. Faced with questions of civic propriety and social justice, he spirals into a sordid affair by the graces of his prejudices, preconceptions of valour and virtue, and by the fickle flails of fate's winds in the streets of temptation; all choreographed and documented in his mind's poetic prose.`

    const paragraph_3 = `Everything has its rhythms, and for Justin it was music and written works of art - the ultimate bellweathers of a truly enlightened society (at least in his view, they were). Haunted by the sinister dredges of his impoverished upbringing, the novel boasts a full feature leangth soundtrack from Justin's proverbial bespoken pen: written, performed, recorded and mixed by the author, me! It's even available in full for free, so long as you're willing to hunt for the url. I've hidden it somewhere visible in my many online breadcrumbs, so check all my links and read carefully if you plan on nabbing the promo edition - mwahahaha!) or for purchase on iTunes, Apple / Google Music, and streaming services like YouTube and Spotify.`
  
    return( <>
    <CustomScrollbar universal style={{ 
        margin: '15px',
        width: 'calc( 89vw - 15px )'
        }} 
        renderTrackVertical={ ({ style, ...props})=>{
            const trackStyle = { backgroundColor: 'rgba(0,0,0,0.5)', width: '5px', top: '50px', bottom:'2px', right: '3px'}
            return ( <div style={{...style, ...trackStyle }} {...props} />)
        } }
        renderThumbVertical={ ({ style, ...props}) =>{ 
            const thumbStyle = { backgroundColor: 'rgba(255,255,255,0.75)'}
            return(
                <div style={ {...style, ...thumbStyle} } {...props}>
                </div>
            )
        }
    }>
    <div className='modal-text-content'>
        <center><a href='https://www.amazon.com/Emergence-Luctor-Emergo-Kristopher-Driver/dp/1517034280' target='_blank' rel="noopener noreferrer"><img src={headerImage} className='mp-header' alt='Emergence: Luctor Et Emergo'/></a></center>
        <h2 className='mp-head-text' >{title}</h2>
        
        <p>{paragraph_1}</p>
        <p>{paragraph_2}</p>
        <p>{paragraph_3}</p>
        
        <center><img src={reviewImage} className='mp-header' alt='Book review for Emergence: Luctor et Emergo'/></center>
    </div>
    </CustomScrollbar>
    </>)
}

export default ModalEmergence