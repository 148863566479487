import React from 'react';
import rssicon from '../img/feed-icon-28x28.png';

const feedAddress = "https://krisdriver.com/feed/rssfeed.xml";
const rssClick = (e) => { navigator.clipboard.writeText(feedAddress); };

const Feed = (props)=>{
    return(<div className='feed-banner' onClick={ 
        (evt)=>{
            rssClick(evt);
        }
        }>
            <img src={rssicon} style={{ boxShadow: '3px 3px 5px black' }} alt='rss icon'/>
            <pre>Click to copy rss feed url</pre>


        
    </div>)
}

export default Feed;