import React, { useState } from 'react';
import Image from './Image';
import Modal from './Modal';
import image1 from '../img/thumbs/meltingPot.jpg';      // the melting pot
import image2 from '../img/thumbs/emergence.jpg';       // emergence
import image3 from '../img/thumbs/album.jpg';           // music
import image4 from '../img/thumbs/graphics.jpg';        // graphics
import image5 from '../img/thumbs/research.jpg';        // research 
import image6 from '../img/thumbs/06.jpg';              // links
import image7 from '../img/thumbs/author.jpg';          // links
 
const src = [image1, image2, image3, image4, image5, image6, image7];

let introAnimationDelay=[];
src.forEach( (v,i)=>{
    let tmp_delay = (idx)=>{ 
        let x = ((idx * 0.25) + 3) + 's';
        return x;
    } 
    let style = {
        animationName: 'slideFromLeft',
        animationDuration: '2s',
        animationTimingFunction: 'ease-out',
        animationDelay: tmp_delay(i),
        animationFillMode: 'forwards',
        animationIterationCount: '1'
    };
    introAnimationDelay.push(style); 
});
 
const txtContent = ['MeltingPot', 'Emergence', 'Music', 'Graphics', 'Research', 'Links', 'Author'];

const Thumbview = ()=>{
    
    const [contentView, setContentView] = useState( {
        display: false,
        content: 'empty'
    });

    const postThumbs = ()=>{ return introAnimationDelay.map( (x, i) => {
            return (
            <div className='thumb-view-container' style={x} key={ txtContent[i] }>
                <Image path={ src[i] } 
                textContent={ txtContent[i] }
                sendTarget={ (response)=>{ setContentView( {display: !contentView.display, content: response}) }} />            
            </div>)
        })
    }

    return (<>
        <div className='thumb-view-wrapper'>
            { postThumbs() }
            { contentView.display && (<Modal content={contentView.content} displayToggle={ ()=>{
                setContentView({ ...contentView, display: !contentView.display })
            }}/>) }
        </div>
        </>
    )
}

export default Thumbview;
