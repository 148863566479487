import React from 'react'
import CustomScrollbar from './CustomScrollbar';
import headerImage from '../img/graphics.jpg';
import imgAbsence from '../img/absence.jpg';
import imgBrace from '../img/brace.jpg';
import imgClassique from '../img/classique.jpg';
import imgDeeze from '../img/deeze.jpg';
   
const ModalGraphics = ()=>{
    const title = `Computer Graphics - Commercial Licenses for Royalty Free Collections for use in graphic design, logos, websites, or any other marketing materials for large and small businesses alike.`

    const paragraph_1 = `Successful computer graphics designers know how crucial it is to have a library of royalty-free commercially licensed images at hand. When selling commercial graphics the onus is on the graphic designer to ensure they have the necessary rights and permissions to use images as part of their composition and production process; which includes non-profit organizations.Whether you’re an expert in the field or new to design entirely, everyone needs stock images to quickly add character, texture, depth, lighting and appeal to both complex and simple designs for anything from brochures that pop to benign desktop wallpapers that exude elegance.`

    const paragraph_2 = `Don’t spend dozens of hours building models and days waiting for them to render. With a collection of royalty-free stock images you can even you parts of a single image for full screen backgrounds, thanks to the enormous image sizes and over 300-600 samples processed per pixel rendered.`

    const paragraph_3 = `The only restrictions on using these images is they must be edited before being sold. They may be used without being edited for personal or commercial use, but you can not sell the graphics in this collection unchanged. Slap a website url in the bottom corner, add your logo or apply a layer filter to the image and it’s yours to call your own without ever needing to worry about copyright or royalties other than those you collect from your customers.`
    
    const paragraph_4 = `Using the images of this collection is as simple or complex as you want it to be. Whether you’re just getting into graphic art as a beginner and need some HD (high-definition) filters to practise with, or you’re a professional who’s been in the business for years; everyone needs a library of useful static graphics to mix, blend and play around with. You buy this collection once and get to use each ultra high definition picture in the collection in absolutely any project, poster or presentation you want.`
    
    const paragraph_5 = `What exactly do you do with these images? Anything you want! Use them for slideshow presentations as backgrounds; use them as filters for your own original creative work; use them for a novel dust jacket, or album cover, or desktop publishing; use them for 3D modelling as texture or displacement maps; make a logo, or website banner for advertising. You are limited only by your imagination.`
    
    const paragraph_6 = `Check out my collections currently for sale by clicking on each of the banners below to see the ebay listings respective to each collection. I do offer exclusive pieces to paying customers, but reserve the right to reject offers at my sole discretion, due to constraints on my time and other projects that I'm working on. Research is always my priority.`

    const absence = ()=>{ 
    return(
    <center>
        <a href='https://www.ebay.ca/itm/233365869034' target='_blank' rel="noopener noreferrer">
            <img src={imgAbsence} className='cg-banner' alt='Absence Collection' />
        </a>
    </center>
    )}
    
    const brace = ()=>{
    return(
    <center>
        <a href='https://www.ebay.ca/itm/233418331517' target='_blank' rel="noopener noreferrer">
            <img src={imgBrace} className='cg-banner' alt='Brace Yourself Collection'/>
        </a>
    </center>
    )}
    
    const classique = ()=>{
    return(
    <center>
        <a href='https://www.ebay.ca/itm/233418333790' target='_blank' rel="noopener noreferrer">
            <img src={imgClassique} className='cg-banner' alt='Classique Collection' />
        </a>
    </center>
    )}
   
    const deeze = ()=>{
    return(
    <center>
        <a href='https://www.ebay.ca/itm/233744116431' target='_blank' rel="noopener noreferrer">
            <img src={imgDeeze} className='cg-banner' alt='Deeze Brushes Collection' />
        </a>
    </center>
    )}

    const lastImage = ()=>{
    return(
    <center>
        <a href='https://www.ebay.ca/itm/233365869034' target='_blank'rel="noopener noreferrer">
        <img src={headerImage} className='cg-banner' alt='Royalty Free Professional Graphics Collection' />
        </a>
    </center>
    )}
    
    return( <>
    <CustomScrollbar universal style={{ 
        margin: '15px',
        width: 'calc( 89vw - 15px )'
        }} 
        renderTrackVertical={ ({ style, ...props})=>{
            const trackStyle = { backgroundColor: 'rgba(0,0,0,0.5)', width: '5px', top: '50px', bottom:'2px', right: '3px'}
            return ( <div style={{...style, ...trackStyle }} {...props} />)
        } }
        renderThumbVertical={ ({ style, ...props}) =>{ 
            const thumbStyle = { backgroundColor: 'rgba(255,255,255,0.75)'}
            return(
                <div style={ {...style, ...thumbStyle} } {...props}>
                </div>
            )
        }
    }>
    <center>
        <div className='graphics-modal'>
            <img src={headerImage} className='cg-header' alt='CG Art'/>
        </div>
    <div className='modal-text-content'>
        <center><h2>{title}</h2></center>
        <p>{paragraph_1}</p>
        {absence()}
        <p>{paragraph_2}</p>
        {brace()}
        <p>{paragraph_3}</p>
        {classique()}
        <p>{paragraph_4}</p>
        {deeze()}
        <p>{paragraph_5}</p>
        {lastImage()}
        <p>{paragraph_6}</p>
    </div>
    </center>
    </CustomScrollbar>
    </>)
}

export default ModalGraphics