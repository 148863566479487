import React, { Fragment, useRef, useEffect } from 'react';

// page data for modal content...
import ModalMeltingPot from './ModalMeltingPot';
import ModalEmergence from './ModalEmergence';
import ModalMusic from './ModalMusic';
import ModalAuthor from './ModalAuthor';
import ModalGraphics from './ModalGraphics';
import ModalResearch from './ModalResearch';
import ModalLinks from './ModalLinks';

const modal_styles = {
    position: 'absolute',
    overflow: 'hidden',
    width: '0vw',   // for transition animation
    height: '0vh',  // for transition animation
    opacity: '0',   // for transition animation
    // left: '-5px',
    // top: '-40vh',
    left: '-3vw',
    color: 'white',
    backgroundColor: 'rgba(33,33,33,0.9)',
    border: '1px solid beige',
    zIndex: '5000',
    transition: 'all 0.2s ease-out'
} 

const Modal = props=>{
   const content = props.content;
   // Fade in once loaded, transition animation
    useEffect(()=>{
        setTimeout( ()=>{
            mymodal.current.style.opacity = 1
            mymodal.current.style.width = '93vw'
            // mymodal.current.style.width = '95%'
            // mymodal.current.style.height = '90vh'
            mymodal.current.style.top = '-35vh'
            mymodal.current.style.left = '0'

            mymodal.current.style.height = '80vh'
        }, 50)
    }, [])
    // Click event handler, modal's content loader
    const closeHandler = (evt)=>{
        mymodal.current.style.opacity = 0;
        setTimeout( ()=>{
            props.displayToggle()
        }, 500); // transition duration is 250ms
    }
    // Self defining reference...
    const mymodal = useRef();

// WORK IN PROGRESS --------------------    
    //  LOAD PAGE DATA INTO THE MODAL
    const selectContentToDisplay = (content)=>{     
        if (content === 'MeltingPot') return <ModalMeltingPot />   // works!!!
        if (content === 'Emergence') return <ModalEmergence /> 
        if (content === 'Music') return <ModalMusic />
        if (content === 'Graphics') return <ModalGraphics />
        if (content === 'Author') return <ModalAuthor />
        if (content === 'Research') return <ModalResearch />

        if (content === 'Links') return <ModalLinks />
    }
    
    return(<Fragment>
        <div style={modal_styles} ref={mymodal}>
            {/* <h4>Modal Displaying Page: {content}</h4> */}
            { selectContentToDisplay(content) }
            <button onClick={ closeHandler } className='close-btn'><span className='close-btn-text'>::Close::</span></button>
        </div>
    </Fragment>)
}

export default Modal;