import React, { useRef } from 'react';

 
const Image = (props) => {

    const actionClick = useRef();
    const textContent = props.textContent;
    const divstyles = {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url('${props.path}')`,
        backgroundPosiition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '90%',
        height: '90%',
        opacity: '0.85',
        transition: 'all 0.28s cubic-bezier(.26,.24,.31,1.48) 0s',
        zIndex: '111'
    }

    return (<>
    <div className='img-card-container'>
    {/* <div className='scanimation'> */}
        <div style={divstyles} className='img-card'>
            <div className='scanimation2'  ref={actionClick} onClick={
                e => { props.sendTarget(textContent) }}>
            </div>
        </div>
    {/* </div> */}
    <div className='textDirection'><p>{textContent}</p></div>
    </div>

    </>)
}

export default Image