import React from 'react'
import CustomScrollbar from './CustomScrollbar';
import headerImage from '../img/extras/author.jpg';
import portrait2 from '../img/extras/portrait2.jpg';
 
const ModalAuthor = ()=>{
    const title = `Kristopher Driver - Author Info`
 
    const paragraph_1 = `Kristopher Driver is a romantic; a music fanatic, a patron of the arts and avid philosopher. He wrote Emergence over the course of 8 years, delving into his psyche as honestly as possible to unearth and confront his demons in the pursuit of self-improvement and personal fulfillment in all aspects of his life. Born and raised in Winnipeg, Manitoba, Canada, Kris began writing early on in his childhood.`
    
    const paragraph_2 = `He took a keen interest in the pursuit of knowledge and the metaphysics inherent therein. At times, his ideological as much as theological pursuit of knowledge and professional development came at the expense of his relationships and health, but through the years he learned to better manage the balance of life's pleasures and work largely due to his introspective predisposition: the subject at hand.`

    const paragraph_3 = `His greatest influences were his relationships and music, crediting the vast majority of his experiences to the people and music he found in his walk of life. Kris's magnum opus, Emergence, is the summary machination of his experiences. When faced with moral dilemmas and questions of honor and integrity, he penned his thoughts and broke them down.`

    const paragraph_4 = `TLDR: #js, #YNWA Liverpool, #canadian, #indie, #life, #love, #food and #travel is the modus operandi. Proud supporter of common sense, open source, financial markets and promoting health awareness. Follow daily updates on Twitter @paxdriver; on facebook at fb.com/emergencenovel.`

    const shoutout = `Besides the arts, a particular shout out of thanks must be made to the open source community of software / framework developers of the world without whom I'd never have so much as published more than tweets. From books, to audio engineering, to self-publishing, to word processing, graphics and web technologies I truly have stood on the backs of giants. I am forever grateful for the hard work of others. In an effort to make use of gifts I've been awarded by no strength of my own, I've created a bunch of free artsy stuff to hopefully inspire others more talented than I to make the world a better place than they left found it. Whether it be by the generation of culture capital or by changing the world for the better, I hope my patchwork of pieces serves to encourage tomorrow's brightest minds and purest hearts that they can do anything. In the 21st century, we find outselves awash with tools if we only take the time to learn how to use them for achieving our fullest potentials.`
    
    const thanks = `Other than software developers, I also I want to give equal credit to the civil servants of the world, who somehow withstood my personality quirks and who dutifully fill this country's essential service roles even during viral pandemics like that of COVID-19. That's teachers, healthcare practitioners, transportation, mail carriers, political administrators (some of them, anyway), teachers, police and emergency service workers, agribulture and food supply chains, and a whole host of others I couldn't possibly have the presence of mind to include explicitly as I write this. Thank you for helping.`

    return( <>
    <CustomScrollbar universal style={{ 
        margin: '15px',
        width: 'calc( 89vw - 15px )'
        }} 
        renderTrackVertical={ ({ style, ...props})=>{
            const trackStyle = { backgroundColor: 'rgba(0,0,0,0.5)', width: '5px', top: '50px', bottom:'2px', right: '3px'}
            return ( <div style={{...style, ...trackStyle }} {...props} />)
        } }
        renderThumbVertical={ ({ style, ...props}) =>{ 
            const thumbStyle = { backgroundColor: 'rgba(255,255,255,0.75)'}
            return(
                <div style={ {...style, ...thumbStyle} } {...props}>
                </div>
            )
        }
    }>
        <h2 className='author-head-text' >{title}</h2>
    <div className='auth-text-content'>
        <center>
            <img src={headerImage} className='author-header' alt='Kris Driver Header' />
            <br /><br /> 
            <img src={portrait2} className='bio-portrait' alt='Kristopher Driver Portrait'/>
        </center>
        <div className='author-text'>
        <p>{paragraph_1}<br /><br />
        {paragraph_2}<br /><br />
        {paragraph_3}<br /><br />
        {paragraph_4}<br /><br />
        {shoutout}<br /><br />
        {thanks}<br /><br />
        </p>
        <h4> Kris Driver is: author, producer, rapper, web developer, foodie, photographer, graphics designer, and audiophile extraordinaire.</h4>
        </div>

    </div>
    </CustomScrollbar>
    </>)
}

export default ModalAuthor