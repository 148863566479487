import React from 'react'
import imgAbsence from '../img/absence.jpg';
import imgBrace from '../img/brace.jpg';
import imgClassique from '../img/classique.jpg';
import imgDeeze from '../img/deeze.jpg';
import imgSoundtrack from '../img/extras/officialsoundtrack.jpg';
import album from '../img/extras/album.jpg';
import imgEmergence from '../img/extras/header-em.jpg';
import vid_animation from "../vid/meltingAnim.webm";
import CustomScrollbar from './CustomScrollbar';
 
  const absence = ()=>{ 
    return(
    <center>
        <a href='https://www.ebay.ca/itm/233365869034' target='_blank' rel="noopener noreferrer">
            <img src={imgAbsence} className='cg-banner' alt='Absence Collection' />
        </a>
    </center>
    )} 
    
    const brace = ()=>{
    return(
    <center>
        <a href='https://www.ebay.ca/itm/233418331517' target='_blank' rel="noopener noreferrer">
            <img src={imgBrace} className='cg-banner' alt='Brace Yourself Collection'/>
        </a>
    </center>
    )}
    
    const classique = ()=>{
    return(
    <center>
        <a href='https://www.ebay.ca/itm/233418333790' target='_blank' rel="noopener noreferrer">
            <img src={imgClassique} className='cg-banner' alt='Classique Collection' />
        </a>
    </center>
    )}
   
    const deeze = ()=>{
    return(
    <center>
        <a href='https://www.ebay.ca/itm/233744116431' target='_blank' rel="noopener noreferrer">
            <img src={imgDeeze} className='cg-banner' alt='Deeze Brushes Collection' />
        </a>
    </center>
    )}
    const video = ()=>{
    return (
        <center>
        <div style={{ padding: '5px', backgroundImage: 'linear-gradient( to bottom right, black, white, white, white, black )', borderRadius: '5%', boxShadow: '3px 3px 5px black' }}>
        <video className='vid-style' controls>
            <source src={vid_animation} type="video/webm" autoPlay loop />
        </video>
        </div>
        </center>
    )
    }
 
const ModalLinks = ()=>{
    const title = `Links and Miscellaneous`

    const old_website = `Probably an poor place to mention this, but I have an affinity for recursion so I'm gonna do it ne-weh: my old website can still be viewed at www.krisdriver.com/old (in case you're on a device that can't view these new fan-dangled technologies like "React")`
    
    const stockimages = `Follow these links to eBay listings of stock image collections available for sale for graphic designers, professional presentation esthetics, or any other commercial purpose other than resale of royalty-free commercially licensed collections as they are presented herein.`
    
    const paragraph_5 = `Check me on twitter @paxdriver`
    
    const paragraph_6 = `Find my facebook page @emergencenovel`
   
    return( <>
    <CustomScrollbar universal style={{ 
        margin: '15px',
        width: 'calc( 89vw - 15px )'
        }} 
        renderTrackVertical={ ({ style, ...props})=>{
            const trackStyle = { backgroundColor: 'rgba(0,0,0,0.5)', width: '5px', top: '50px', bottom:'2px', right: '3px'}
            return ( <div style={{...style, ...trackStyle }} {...props} />)
        } }
        renderThumbVertical={ ({ style, ...props}) =>{ 
            const thumbStyle = { backgroundColor: 'rgba(255,255,255,0.75)'}
            return(
                <div style={ {...style, ...thumbStyle} } {...props}>
                </div>
            )
        }
    }>
    <center>
        <h2>{title}</h2>
        <p>{old_website}</p>
    </center>

    <div className='modal-text-links'>
        <a href='https://www.blurb.com/b/9181642-the-melting-pot' target='_blank' rel="noopener noreferrer">
            <h2>The Melting Pot - A Canadian Guide to Cooking & Nutrition for Managing Chronic Health Conditions</h2>
            <br />
            {video()}
        <br />
        </a>
        

  		<h4>Soundcloud Links:</h4>
		<ul>
			<li><a href="https://soundcloud.com/kristopher-driver/waiting-for-a-song-produced-by-dov-rohan" target="_blank" rel="noopener noreferrer"><h4>Waiting for a Song</h4></a></li>
			<li><a href="https://soundcloud.com/kristopher-driver/bar-stars-prod-by-blanq-beatz" target="_blank" rel="noopener noreferrer"><h4>Bar Stars</h4></a></li>
			<li><a href="https://soundcloud.com/kristopher-driver/consumer-divorce-produced-by-epistra" target="_blank" rel="noopener noreferrer"><h4>Consumer Divorce</h4></a></li>
			<li><a href="https://soundcloud.com/kristopher-driver/believe-in-me-produced-by-epistra" target="_blank" rel="noopener noreferrer"><h4>Believe in Me</h4></a></li>
			<li><a href="https://soundcloud.com/kristopher-driver/moving-on-produced-by-don-p-1" target="_blank" rel="noopener noreferrer"><h4>Moving On</h4></a></li>
		</ul>
<hr />

<a href="http://itunes.apple.com/us/album/emergence-luctor-et-emergo-official-soundtrack/1296965258" target="_blank" rel="noopener noreferrer">
    <h4>Link to the official soundtrack for "Emergence: Luctor Et Emergo" </h4>
    <center>
    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-evenly" }}>
        <img src={imgSoundtrack} alt='Emergence Soundtrack' style={{ width: '35vw', objectFit: 'contain' }} />
        <img src={album} alt='Emergence Soundtrack' style={{ width: '35vw', objectFit: 'contain' }} />
    </div>
    </center>
</a>
<span> </span>

<br /><br />

<a href="http://www.amazon.com/Emergence-Luctor-Emergo-Kristopher-Driver/dp/1517034280" target="_blank" rel="noopener noreferrer">
<h4>Link to book: Emergence - Luctor et Emergo is available in both print and e-reader formats (including Kindle)</h4>
    <center><img src={imgEmergence} alt='Emergence Soundtrack' style={{ width: '35vw', objectFit: 'cover' }} /></center>
</a>
<span> </span>
<hr />
        <p>{stockimages}</p>
        {absence()}
        {brace()}
        {classique()}
        {deeze()}
<hr />
        <p>{paragraph_5}</p>

        <p>{paragraph_6}</p>
    </div>
    </CustomScrollbar>
    </>)
}

export default ModalLinks