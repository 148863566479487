import React, { useEffect, useState } from 'react';
import './App.css';
import Main from './modules/Main';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import Feed from './modules/Feed';

function App() {
  // Sets loading bool while caching and showing spinners...
  const [state, setState] = useState({ loading: true });
  
  useEffect( ()=>{
    setTimeout( ()=>{
      setState({loading: false})
    }, 2000);
  }, []); 

  return (
    <>

      { (state.loading) ? (<div className='spinner-div'><center>
      <div className='spinner-div-height'>
      <h1 style={{color: 'white'}}>Loading...</h1>
        <Loader className='loaderSpinner' type="Bars" color='#DDDDDD' />
        <Loader className='loaderSpinner' type="Grid" color='#DDDDDD' />
        <Loader className='loaderSpinner' type="ThreeDots" color='#DDDDDD' />
      </div>
      </center>
      </div>) : ( <div className="App"><Feed /><Main /></div> )}
    </>
  );
}

export default App;






/*  
$ npm install react-loader-spinner --save 
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
-------------------------------------------------------------------------------
Types of Spinner:
react-loader-spinner component has the following types of spinners.

Spinner Type	Implementation
Audio	<Loader type="Audio" color="#00BFFF" height={80} width={80} />
Ball-Triangle	<Loader type="BallTriangle" color="#00BFFF" height={80} width={80} />
Bars	<Loader type="Bars" color="#00BFFF" height={80} width={80} />
Circles	<Loader type="Circles" color="#00BFFF" height={80} width={80}/>
Grid	<Loader type="Grid" color="#00BFFF" height={80} width={80} />
Hearts	<Loader type="Hearts" color="#00BFFF" height={80} width={80} />
Oval	<Loader type="Oval" color="#00BFFF" height={80} width={80} />
Puff	<Loader type="Puff" color="#00BFFF" height={80} width={80} />
Rings	<Loader type="Rings" color="#00BFFF" height={80} width={80} />
TailSpin	<Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
ThreeDots	<Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
-------------------------------------------------------------------------------
*/ 


// ATTEMPT AT PRELOADING 1
  // Provide array of image urls, have react create them but not use them. 
  // Use the async promises returned mapped to each image url of the input array 
  //  to delay the loading state switch to after the images used in other components 
  //  are cached before called into use...
  // After cache is complete, promises all complete, set the state of loading to false...
  // const cacheImages = async (srcArray)=>{
  //   const promises = await srcArray.map((src) =>{
  //     return (new Promise( 
  //       function(resolve, reject) {
  //         const img = new React.createElement('img', { src: src, onLoad: resolve(), onerror: reject(), alt: 'loading' }, '' );
  //       }));
  //   });
    // wait for images to be cached...
    // console.log('awaiting...')
    //   await Promise.all(promises);
      // setState({ loading : false });
  // }


/*
  useEffect( ()=>{
    const imgs = [
      './img/podcast-feathered.png',
      './img/thumbs/01-trans.png',
      './img/thumbs/02-trans.png',
      './img/thumbs/02C-trans.png',
      './img/thumbs/03-trans.png',
      './img/thumbs/04-trans.png',
      './img/thumbs/05-trans.png',
      './img/thumbs/06.jpg'
      // 'url("./img/podcast-feathered.png")',
      // 'url("./img/thumbs/01-trans.png")',
      // 'url("./img/thumbs/02-trans.png")',
      // 'url("./img/thumbs/02C-trans.png")',
      // 'url("./img/thumbs/03-trans.png")',
      // 'url("./img/thumbs/04-trans.png")',
      // 'url("./img/thumbs/05-trans.png")',
      // 'url("./img/thumbs/06.jpg")'
    ];
    cacheImages(imgs);
    setTimeout( function(){
      setState( { loading: false } )
    }, 600)  
  }, []);
*/